import { Observable } from "rxjs";
import { fromFetch } from "rxjs/fetch";
import { switchMap, tap } from "rxjs/operators";
import { singleton } from "tsyringe";
import urlJoin from "url-join";

import { LoggerInterface } from "@interfaces/LoggerInterface";

import ApiClientInterface from "./ApiClientInterface";
import ApiClientRequest, {
    ApiClientHeaders,
    ApiClientQueryParams,
    ApiClientResponseType,
} from "./ApiClientRequest";
import { ApiClientResponse } from "./ApiClientResponse";
import { BAD_STATUS, DELETE, GET, PATCH, POST, PUT } from "./constants";
import {
    BadRequestException,
    ForbiddenException,
    GatewayTimeoutException,
    InternalServerErrorException,
    NotFoundException,
    ToManyRequestException,
    UnauthorizedException,
} from "./exceptions";

const responseToApiClientResponse = <T extends string>(
    response: Response,
    responseType: ApiClientResponseType
): Promise<ApiClientResponse<T>> => {
    return response.text().then((text) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const body = responseType === "json" ? JSON.parse(text) : text;
        return {
            body: body as T,
            status: response.status,
            statusText: response.statusText,
            headers: response.headers,
            redirected: response.redirected,
            url: response.url,
        };
    });
};

@singleton()
class ApiClient implements ApiClientInterface {
    constructor(
        private readonly baseURL: string = "/",
        private readonly headers: ApiClientHeaders = {},
        private readonly params: ApiClientQueryParams = {},
        private readonly logger?: LoggerInterface
    ) {}
    req(reqConfig: ApiClientRequest): Observable<ApiClientResponse<unknown>> {
        const responseType = reqConfig.responseType || "json";
        if (
            [POST, PATCH, PUT].includes(reqConfig.method) &&
            !reqConfig.contentType
        ) {
            this.logger?.warn(
                "You use POST/PATH method of ApiClient without passing contentType"
            );
        }
        if (reqConfig.contentType) {
            if (!reqConfig.headers) {
                reqConfig.headers = {};
            }
            reqConfig.headers["Content-Type"] = reqConfig.contentType;
        }
        const request$ = new Observable<Request>((subscriber) => {
            const qs = new URLSearchParams();
            const headers = new Headers();
            Object.entries({
                ...this.params,
                ...reqConfig.queryParams,
            }).forEach(([key, value]) => {
                qs.append(key, value.toString());
            });
            Object.entries({
                ...this.headers,
                ...reqConfig.headers,
            }).forEach(([key, value]) => {
                headers.append(key, value);
            });
            const baseURLWithCurrentOrigin = urlJoin(
                this.baseURL,
                reqConfig.url
            );
            const url = new URL(baseURLWithCurrentOrigin, location.origin);
            url.search = `?${qs.toString()}`;
            subscriber.next(
                new Request(url.toString(), {
                    method: reqConfig.method,
                    body: reqConfig.body,
                    headers: headers,
                    credentials: reqConfig.credentials,
                })
            );
            subscriber.complete();
        });
        return request$.pipe(
            switchMap((request) => {
                // TODO: Vladimir - посмотреть finalize
                return fromFetch(request);
            }),
            tap((response) => {
                if (response.status >= BAD_STATUS) {
                    switch (response.status) {
                        case BadRequestException.STATUS_CODE:
                            throw new BadRequestException(reqConfig, response);
                        case InternalServerErrorException.STATUS_CODE:
                            throw new InternalServerErrorException(
                                reqConfig,
                                response
                            );
                        case ForbiddenException.STATUS_CODE:
                            throw new ForbiddenException(reqConfig, response);
                        case GatewayTimeoutException.STATUS_CODE:
                            throw new GatewayTimeoutException(
                                reqConfig,
                                response
                            );
                        case NotFoundException.STATUS_CODE:
                            throw new NotFoundException(reqConfig, response);
                        case ToManyRequestException.STATUS_CODE:
                            throw new ToManyRequestException(
                                reqConfig,
                                response
                            );
                        case UnauthorizedException.STATUS_CODE:
                            throw new UnauthorizedException(
                                reqConfig,
                                response
                            );
                    }
                }
            }),
            switchMap((response) =>
                responseToApiClientResponse(response, responseType)
            )
        );
    }
    get(
        reqConfig: Omit<ApiClientRequest, "method" | "body">
    ): Observable<ApiClientResponse<unknown>> {
        return this.req({
            ...reqConfig,
            method: GET,
        });
    }
    delete(
        reqConfig: Omit<ApiClientRequest, "method" | "body">
    ): Observable<ApiClientResponse<unknown>> {
        return this.req({
            ...reqConfig,
            method: DELETE,
        });
    }
    post(
        reqConfig: Omit<ApiClientRequest, "method">
    ): Observable<ApiClientResponse<unknown>> {
        return this.req({
            ...reqConfig,
            method: POST,
        });
    }
    put(
        reqConfig: Omit<ApiClientRequest, "method">
    ): Observable<ApiClientResponse<unknown>> {
        return this.req({
            ...reqConfig,
            method: PUT,
        });
    }
    patch(
        reqConfig: Omit<ApiClientRequest, "method">
    ): Observable<ApiClientResponse<unknown>> {
        return this.req({
            ...reqConfig,
            method: PATCH,
        });
    }
}

export default ApiClient;
