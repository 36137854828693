const formatWithDelimiters =
    (precision = 2, thousands = ",", decimal = ".") =>
    (number: number): string => {
        const price = number.toFixed(precision);

        const parts = price.split(".");
        const ceils = parts[0].replace(
            /(\d)(?=(\d\d\d)+(?!\d))/g,
            "$1" + thousands
        );
        const cents = parts[1] ? decimal + parts[1] : "";

        return ceils + cents;
    };

export const FORMATS = {
    amount: formatWithDelimiters(2),
    amount_no_decimals: formatWithDelimiters(0),
    amount_with_comma_separator: formatWithDelimiters(2, ".", ","),
    amount_no_decimals_with_comma_separator: formatWithDelimiters(0, ".", ","),
    amount_with_apostrophe_separator: formatWithDelimiters(2, "'", "."),
};

const PLACEHOLDER_REGEX = /\{\{\s*(\w+)\s*\}\}/;

class MoneyFormatter {
    constructor(
        private readonly money_format: string,
        private readonly money_with_currency_format: string
    ) {}
    private f(format_string: string, money: number): string {
        const match = format_string.match(PLACEHOLDER_REGEX);
        if (!match) {
            throw new Error("incorrect format string(without placeholder)");
        }
        const format = match[1];
        if (Object.keys(FORMATS).includes(format)) {
            const value = FORMATS[format as keyof typeof FORMATS](money);
            return format_string.replace(PLACEHOLDER_REGEX, value);
        } else {
            throw new Error("unknown format");
        }
    }
    format(money: number): string {
        return this.f(this.money_format, money);
    }
    format_with_currency(money: number): string {
        return this.f(this.money_with_currency_format, money);
    }
}

export default MoneyFormatter;
