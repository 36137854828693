import { getJsonFromScript } from "@lib/utils";
import { Infer, create } from "superstruct";

import { LoggerInterface } from "@interfaces/LoggerInterface";

import GrowaveFeaturesBodySchema from "./GrowaveFeaturesBodySchema";

const FEATURES_ELEMENT_SELECTOR = "#gw-features";

class GrowaveFeaturesProvider {
    constructor(private readonly logger: LoggerInterface) {}
    getFeatures(): Infer<typeof GrowaveFeaturesBodySchema> {
        const parsedBody = getJsonFromScript(FEATURES_ELEMENT_SELECTOR);
        const features = create(parsedBody, GrowaveFeaturesBodySchema);
        this.logger.debug("GrowaveFeaturesProvider.getFeatures", {
            features,
        });
        return features;
    }
}

export default GrowaveFeaturesProvider;
