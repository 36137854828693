import FetchAndXHRPatcher, {
    CatchedRequest,
} from "@lib/FetchAndXHRPatcher/FetchAndXHRPatcher";
import { Observable, combineLatest, from, of } from "rxjs";
import { filter, map, switchMap } from "rxjs/operators";

import { LoggerInterface } from "@interfaces/LoggerInterface";

import CartListenerStrategyInterface from "./CartListenerStrategyInterface";
import { AddToCartEvent } from "./types";

class FetchOrXHRPatcherListenerStrategy
    implements CartListenerStrategyInterface
{
    constructor(
        private readonly logger: LoggerInterface,
        private readonly fetchAndXhrPatcher: FetchAndXHRPatcher
    ) {}
    listen(): Observable<AddToCartEvent> {
        return this.fetchAndXhrPatcher.requests$.pipe(
            filter((request: CatchedRequest) => {
                return request.url.includes("/cart/add");
            }),
            filter(
                (
                    request: CatchedRequest
                ): request is Omit<CatchedRequest, "body"> & {
                    body: FormData;
                } => {
                    return request.body instanceof FormData;
                }
            ),
            // combine request and response for waiting response before processins next steps
            switchMap((request) => {
                return combineLatest([of(request), from(request.response)]);
            }),
            map(([request]) => {
                return request.body.get("id");
            }),
            filter((id): id is string => !!id),
            map((variantId) => {
                this.logger.debug(
                    "FetchOrXHRPatcherListenerStrategy receive request info",
                    {
                        variantId,
                    }
                );
                return {
                    variantId: parseInt(variantId),
                };
            })
        );
    }
}

export default FetchOrXHRPatcherListenerStrategy;
