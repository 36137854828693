import Minilog, { enable as minilogEnable } from "minilog";

import { LoggerInterface } from "@interfaces/LoggerInterface";

export class MinilogLogger implements LoggerInterface {
    minilog: Minilog;
    constructor(name: string) {
        this.minilog = Minilog(name);
        minilogEnable();
    }
    debug(message: string, additionalFileds?: unknown): void {
        this.minilog.debug(...[message, additionalFileds].filter((v) => !!v));
    }
    info(message: string, additionalFileds?: unknown): void {
        this.minilog.info(...[message, additionalFileds].filter((v) => !!v));
    }
    warn(message: string, additionalFileds?: unknown): void {
        this.minilog.warn(...[message, additionalFileds].filter((v) => !!v));
    }
    error(message: string, additionalFileds?: unknown): void {
        this.minilog.error(...[message, additionalFileds].filter((v) => !!v));
    }
}

export default MinilogLogger;
