import ApiClientRequest from "../ApiClientRequest";
import BaseApiClientException from "./BaseApiClientException";
import { ApiClientResponse } from "..";

class NotFoundException extends BaseApiClientException {
    static STATUS_CODE = 400;
    constructor(
        request: ApiClientRequest,
        response: ApiClientResponse<unknown>
    ) {
        super(
            "Resource not found",
            NotFoundException.STATUS_CODE,
            request,
            response
        );
    }
}

export default NotFoundException;
