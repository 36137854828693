import LocationControllerInterface from "./LocationControllerInterfce";

class LocationController implements LocationControllerInterface {
    goTo(link: string): void {
        location.href = link;
    }
    getOrigin(): string {
        return location.origin;
    }
    getSearchParams(): URLSearchParams {
        const url = new URL(location.href);
        return url.searchParams;
    }
}

export default LocationController;
