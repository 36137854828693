export const INITIAL_LOADING = "INITIAL_LOADING"; // On first data loading (for skeleton showing)
export const LOADING = "LOADING"; // On second+ data loading (when not need skeleton showing) (get request)
export const ERROR = "ERROR"; // On error
export const READY = "READY"; // On request is done
export const VIRGIN = "VIRGIN"; // When request not starts (initial status)

export type STATUS_TYPE =
    | typeof INITIAL_LOADING
    | typeof LOADING
    | typeof ERROR
    | typeof READY
    | typeof VIRGIN;
