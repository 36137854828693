import { Subject } from "rxjs";

import MetricsEvent from "./Event";
import EventsCollectorInterface from "./EventsCollectorInterface";

class RxJSEventsCollector implements EventsCollectorInterface {
    events$: Subject<MetricsEvent>;
    constructor() {
        this.events$ = new Subject();
    }
    pushEvent(event: MetricsEvent): void {
        this.events$.next(event);
    }
}

export default RxJSEventsCollector;
