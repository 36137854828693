import { literal, nullable, number, string, type, union } from "superstruct";

import { CUSTOMER_ROLE, GUEST_ROLE } from "../constants";

const GrowaveJwtTokenSchema = type({
    iat: number(),
    exp: number(),
    data: type({
        sessionToken: string(),
        customerId: nullable(number()),
        role: union([literal(GUEST_ROLE), literal(CUSTOMER_ROLE)]),
    }),
});

export default GrowaveJwtTokenSchema;
