import { CartType } from "./CartManagerInterface";
import CartManagerItemsUpdaterInterface from "./CartManagerItemsUpdaterInterface";

class CartManagerItemsUpdatersContext {
    private updaters: CartManagerItemsUpdaterInterface[] = [];
    addItemsUpdater(counterUpdater: CartManagerItemsUpdaterInterface) {
        this.updaters.push(counterUpdater);
    }
    async updateItems(cart: CartType): Promise<boolean> {
        for (const updater of this.updaters) {
            const result = await updater.updateItems(cart);
            if (result) return result;
        }
        return false;
    }
}

export default CartManagerItemsUpdatersContext;
