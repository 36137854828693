import EventsCollectorInterface from "@lib/EventsCollector/EventsCollectorInterface";
import { lastValueFrom } from "rxjs";
import { bufferTime, filter } from "rxjs/operators";

import MetricsCollectorServiceInteraface from "./MetricsCollectorServiceInterface";

const BUFFER_TIME = 3000;

type MetricsEventsReporterDefaultPayload = {
    app: string;
    version: string;
    userAgent: string;
    env: string;
};

class MetricsEventsReporter {
    constructor(
        private readonly collector: EventsCollectorInterface,
        private readonly metricsCollectorService: MetricsCollectorServiceInteraface,
        private readonly payload: MetricsEventsReporterDefaultPayload
    ) {}
    start(): void {
        this.collector.events$
            .pipe(
                bufferTime(BUFFER_TIME),
                filter((events) => !!events.length)
            )
            .subscribe((metricsEvents) => {
                const modifiedMetricsEvents = metricsEvents.map(
                    (metricsEvent) => ({
                        ...metricsEvent,
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                        payload: {
                            ...metricsEvent.payload,
                            ...this.payload,
                        },
                    })
                );
                void lastValueFrom(
                    this.metricsCollectorService.sendEvents(
                        modifiedMetricsEvents
                    )
                );
            });
    }
}

export default MetricsEventsReporter;
