import { getUnixTime, isBefore } from "date-fns";
import jwtDecode from "jwt-decode";
import { BehaviorSubject, Observable, of } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { Infer, assert, create } from "superstruct";

import { LoggerInterface } from "@interfaces/LoggerInterface";

import { GUEST_ROLE } from "./constants";
import RefreshTokenServiceInterface from "./RefreshTokenServiceInterface";
import GrowaveJwtTokenSchema from "./schemas/GrowaveJwtTokenSchema";
import TokenManagerInterface from "./TokenManagerInterface";

export const TOKEN_STORAGE_KEY = "GW_TOKEN";
export const REFRESH_TOKEN_STORAGE_KEY = "GW_REFRESH_TOKEN";

class GrowaveTokenManager implements TokenManagerInterface {
    public isInitialized = new BehaviorSubject<boolean>(false);
    constructor(
        private readonly refreshTokenService: RefreshTokenServiceInterface,
        private readonly storage: Storage,
        private readonly logger: LoggerInterface
    ) {}

    getToken(): string | null {
        if (this.hasToken()) {
            return this.storage.getItem(TOKEN_STORAGE_KEY);
        }
        return null;
    }

    setToken(token: string): void {
        this.storage.setItem(TOKEN_STORAGE_KEY, token);
    }

    setIsInitialized(): void {
        this.isInitialized.next(true);
        this.logger.debug("GrowaveTokenManager.setIsInitialized");
    }

    getRefreshToken(): string | null {
        return this.storage.getItem(REFRESH_TOKEN_STORAGE_KEY);
    }

    setRefreshToken(refreshToken: string): void {
        this.storage.setItem(REFRESH_TOKEN_STORAGE_KEY, refreshToken);
    }

    getTokenPayload(): Infer<typeof GrowaveJwtTokenSchema> | null {
        const token = this.getToken();
        if (!token) {
            return null;
        }
        const payload = create(jwtDecode(token), GrowaveJwtTokenSchema);
        return payload;
    }

    isGuest(): boolean {
        return this.getTokenPayload()?.data.role === GUEST_ROLE;
    }

    hasToken(): boolean {
        const token = this.storage.getItem(TOKEN_STORAGE_KEY);
        if (!token) return false;
        try {
            assert(jwtDecode(token), GrowaveJwtTokenSchema);
            return true;
        } catch (error: unknown) {
            this.logger.warn("Token is not valid", { token, error });
            this.discardToken();
            return false;
        }
    }

    tokenIsExpired(): boolean {
        const payload = this.getTokenPayload();
        if (payload) {
            return isBefore(payload.exp, getUnixTime(new Date()));
        }

        return false;
    }

    isValidToken(): boolean {
        return this.hasToken() && !this.tokenIsExpired();
    }

    discardToken(): void {
        this.storage.removeItem(TOKEN_STORAGE_KEY);
        this.storage.removeItem(REFRESH_TOKEN_STORAGE_KEY);
    }

    /**
     * Этот метод обновит токен, если токен/рефреш-токен уже имеется
     * В противном случае вернется False
     */
    updateToken(): Observable<boolean> {
        if (!this.hasToken()) {
            this.logger.error("updateToken without token");
            return of(false);
        }
        const refreshToken = this.getRefreshToken();
        if (!refreshToken) {
            this.logger.error("updateToken without refresh token");
            this.discardToken();
            return of(false);
        }
        return this.refreshTokenService.refreshToken(refreshToken).pipe(
            tap((tokens) => {
                this.setToken(tokens.token);
                this.setRefreshToken(tokens.refreshToken);
            }),
            map(() => true),
            catchError((error: unknown) => {
                return of(false).pipe(
                    tap(() => {
                        this.logger.warn("Error on update token", { error });
                        this.discardToken();
                    })
                );
            })
        );
    }
}

export default GrowaveTokenManager;
