import LocationControllerInterface from "@lib/LocationController/LocationControllerInterfce";

class HistoryPatcher {
    constructor(
        private readonly seach_params: string[],
        private readonly history: History,
        private readonly locationController: LocationControllerInterface
    ) {}
    patchFunction(
        origFunc: (...args: Parameters<History["pushState"]>) => void
    ): (...args: Parameters<History["pushState"]>) => void {
        return (...args: Parameters<typeof origFunc>) => {
            const searchParams = this.locationController.getSearchParams();
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const [state, unused] = args;
            let [, , url] = args;
            if (url) {
                const _url = new URL(url, this.locationController.getOrigin());
                for (const [key, value] of searchParams.entries()) {
                    if (this.seach_params.includes(key)) {
                        _url.searchParams.append(key, value);
                    }
                }
                url = _url.toString();
            }
            origFunc(state, unused, url);
            args[0];
        };
    }
    patch() {
        const originalPushState = this.history.pushState.bind(this.history);
        const originalReplaceState = this.history.replaceState.bind(
            this.history
        );
        this.history.pushState = this.patchFunction(originalPushState);
        this.history.replaceState = this.patchFunction(originalReplaceState);
    }
}

export default HistoryPatcher;
