import TokenManagerInterface from "@lib/TokenManager/TokenManagerInterface";
import { Observable, from, of } from "rxjs";
import { filter, map, switchMap, take } from "rxjs/operators";

import ApiClientInterface from "./ApiClientInterface";
import ApiClientRequest from "./ApiClientRequest";
import { ApiClientResponse } from ".";

class AuthApiClient implements ApiClientInterface {
    constructor(
        private readonly apiClient: ApiClientInterface,
        private readonly tokenManager: TokenManagerInterface
    ) {}
    selectToken(): Observable<string> {
        return this.tokenManager.isInitialized.pipe(
            filter((initialized) => initialized),
            take(1),
            switchMap(() =>
                this.tokenManager.isValidToken()
                    ? of(this.tokenManager.getToken())
                    : from(this.tokenManager.updateToken()).pipe(
                          map(() => {
                              return this.tokenManager.getToken();
                          })
                      )
            ),
            map((token) => {
                if (!token) {
                    throw new Error(
                        "Cannot get token from token manager in AuthApiClient"
                    );
                }
                return token;
            })
        );
    }
    get(
        reqConfig: Omit<ApiClientRequest, "method" | "body">
    ): Observable<ApiClientResponse<unknown>> {
        return this.selectToken().pipe(
            switchMap((token) => {
                return this.apiClient.get({
                    ...reqConfig,
                    queryParams: {
                        ...reqConfig.queryParams,
                        token,
                    },
                });
            })
        );
    }
    delete(
        reqConfig: Omit<ApiClientRequest, "method" | "body">
    ): Observable<ApiClientResponse<unknown>> {
        return this.selectToken().pipe(
            switchMap((token) => {
                return this.apiClient.delete({
                    ...reqConfig,
                    queryParams: {
                        ...reqConfig.queryParams,
                        token,
                    },
                });
            })
        );
    }
    post(
        reqConfig: Omit<ApiClientRequest, "method">
    ): Observable<ApiClientResponse<unknown>> {
        return this.selectToken().pipe(
            switchMap((token) => {
                return this.apiClient.post({
                    ...reqConfig,
                    queryParams: {
                        ...reqConfig.queryParams,
                        token,
                    },
                });
            })
        );
    }
    put(
        reqConfig: Omit<ApiClientRequest, "method">
    ): Observable<ApiClientResponse<unknown>> {
        return this.selectToken().pipe(
            switchMap((token) => {
                return this.apiClient.put({
                    ...reqConfig,
                    queryParams: {
                        ...reqConfig.queryParams,
                        token,
                    },
                });
            })
        );
    }
    patch(
        reqConfig: Omit<ApiClientRequest, "method">
    ): Observable<ApiClientResponse<unknown>> {
        return this.selectToken().pipe(
            switchMap((token) => {
                return this.apiClient.patch({
                    ...reqConfig,
                    queryParams: {
                        ...reqConfig.queryParams,
                        token,
                    },
                });
            })
        );
    }
}

export default AuthApiClient;
