export const getJsonFromScript = (selector: string): unknown => {
    let parsedBody = null;
    const element = document.querySelector(selector);
    if (!element) {
        throw new Error("element not found");
    }
    try {
        parsedBody = JSON.parse(element.textContent || "") as unknown;
    } catch (e: unknown) {
        throw new Error("json parse error");
    }
    return parsedBody;
};
