import { LOADING, READY, STATUS_TYPE, VIRGIN } from "@/constants/statuses";
import CartApiServiceInterface from "@modules/cart/services/CartService/CartApiServiceInterface";
import { BehaviorSubject, Observable, lastValueFrom } from "rxjs";
import { filter, map, mergeMap, startWith } from "rxjs/operators";

import { LoggerInterface } from "@interfaces/LoggerInterface";

import CartManagerCounterUpdatersContext from "./CartManagerCounterUpdatersContext";
import CartManagerInterface, { CartType } from "./CartManagerInterface";
import CartManagerItemsUpdatersContext from "./CartManagerItemsUpdatersContext";

class ShopifyCartManager implements CartManagerInterface {
    private cartStatuses$ = new BehaviorSubject<
        {
            variantId: number;
            status: STATUS_TYPE;
        }[]
    >([]);
    constructor(
        private readonly cartService: CartApiServiceInterface,
        private readonly logger: LoggerInterface,
        private readonly counterUpdatersContext: CartManagerCounterUpdatersContext,
        private readonly itemsUpdatersContext: CartManagerItemsUpdatersContext
    ) {}
    public getCart(): Observable<CartType> {
        return this.cartService.getCart();
    }
    async addToCart(
        items: { variantID: number; quantity: number }[]
    ): Promise<CartType> {
        this.cartStatuses$.next(
            items.map(({ variantID }) => ({
                variantId: variantID,
                status: LOADING,
            }))
        );
        await lastValueFrom(this.cartService.addToCart(items)).then(() => {
            this.cartStatuses$.next(
                items.map(({ variantID }) => ({
                    variantId: variantID,
                    status: READY,
                }))
            );
        });
        return await this.applyUpdaters();
    }
    async updateCart(
        updates: { variantID: number; quantity: number }[]
    ): Promise<CartType> {
        await lastValueFrom(this.cartService.updateCart(updates));
        return await this.applyUpdaters();
    }
    async applyUpdaters(): Promise<CartType> {
        this.logger.debug("ShopifyCartManager.applyUpdaters starts");
        // TODO: Добавить optimistic поведение
        const cart = await lastValueFrom(this.getCart());
        const [counterUpdaterResult, itemsUpdaterResult] =
            await Promise.allSettled([
                this.counterUpdatersContext.updateCount(cart.item_count),
                this.itemsUpdatersContext.updateItems(cart),
            ]);
        this.logger.debug("ShopifyCartManager.applyUpdaters finish", {
            counterUpdaterStatus: counterUpdaterResult,
            itemsUpdaterStatus: itemsUpdaterResult,
        });

        return cart;
    }

    selectVariantStatus(variantId: number): Observable<STATUS_TYPE> {
        return this.cartStatuses$.pipe(
            mergeMap((s) => s),
            filter((status) => {
                return status.variantId === variantId;
            }),
            map((status) => {
                return status.status;
            }),
            startWith(VIRGIN as STATUS_TYPE)
        );
    }
}

export default ShopifyCartManager;
