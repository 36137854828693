type I18nLoaderExceptionContext = Record<string, any>;

class I18nLoaderException extends Error {
    context?: I18nLoaderExceptionContext;
    constructor(msg: string, context?: I18nLoaderExceptionContext) {
        super(msg);
        this.context = context;
    }
}

export default I18nLoaderException;
