import { ApiClientResponse, JsonMimeType } from "@lib/ApiClient";
import ApiClientInterface from "@lib/ApiClient/ApiClientInterface";
import MetricsEvent from "@lib/EventsCollector/Event";
import MetricsCollectorServiceInteraface from "@lib/EventsReporter/MetricsCollectorServiceInterface";
import { Observable } from "rxjs";

import { LoggerInterface } from "@interfaces/LoggerInterface";

class MetricsCollectorService implements MetricsCollectorServiceInteraface {
    constructor(
        private readonly apiClient: ApiClientInterface,
        private readonly logger: LoggerInterface
    ) {}
    sendEvents(events: MetricsEvent[]): Observable<ApiClientResponse<unknown>> {
        this.logger.debug("MetricsCollectorService.sendEvents", {
            events,
        });
        return this.apiClient.post({
            url: "/addEvents",
            body: JSON.stringify({ events }),
            responseType: "text",
            contentType: JsonMimeType,
        });
    }
}

export default MetricsCollectorService;
